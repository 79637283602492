var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": "",
        transition: "scale-transition",
        "offset-y": "",
        "nudge-top": "25",
        "max-width": "290px",
        "min-width": "290px",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-text-field",
                _vm._g(
                  {
                    attrs: {
                      attrs: attrs,
                      value: _vm.dateFormatted,
                      label: _vm.label || "Date",
                      "background-color": "input",
                      required: _vm.required,
                      rules: _vm.rules || [],
                      "prepend-inner-icon": "mdi-calendar",
                      "validate-on-blur": "",
                      readonly: "",
                      disabled: _vm.disabled === true,
                      outlined: "",
                      dense: "",
                    },
                  },
                  _vm.disabled ? null : on
                )
              ),
            ]
          },
        },
      ]),
    },
    [
      _c("v-date-picker", {
        attrs: {
          min: _vm.minDate || undefined,
          max: _vm.maxDate || _vm.nowDate,
          "show-current": _vm.currentDate || true,
          "no-title": "",
        },
        model: {
          value: _vm.date,
          callback: function ($$v) {
            _vm.date = $$v
          },
          expression: "date",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }